import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/footer/Footer";

import Header from "./components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/home";
import PrivacyPolicy from "./page/privacy-policy";
import TermsAndConditions from "./page/terms-and-conditions";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#54ABEE",
    },
    primary: {
      main: "#FFAC33",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <main>
          <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import Features from "../components/features/Features";
import Intro from "../components/intro/Intro";

function Home() {
  return (
    <>
      <Intro />
      <Features />
    </>
  );
}

export default Home;

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

interface Feature {
  title: string;
  image: string;
  imageAlt: string;
  description: string;
}

interface Props {
  feature: Feature;
}

function FeatureItem({ feature }: Props) {
  const { title, image, imageAlt, description } = feature;

  return (
    <Grid item sm={4}>
      <Card>
        <CardMedia component='img' height='225' image={image} alt={imageAlt} />
        <CardContent>
          <Typography align='center' mt={1}>
            {description}
          </Typography>
          <Typography gutterBottom variant='h4' align='center' mt={1}>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default FeatureItem;

import { Container } from "@mui/material";

function TermsAndConditions() {
  return (
    <Container>
      <div>
        <h1>Terms and Conditions</h1>
      </div>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Torino Ventures website or services, you
          agree to comply with and be bound by these Terms and Conditions.
        </p>

        <h2>2. Use of Services</h2>
        <p>
          You must use Torino Ventures' services in accordance with applicable
          laws and regulations. Any misuse or unauthorized use may result in
          termination of services.
        </p>

        <h2>3. User Accounts</h2>
        <p>
          To access certain services, you may be required to create an account.
          You are responsible for maintaining the confidentiality of your
          account information.
        </p>

        <h2>4. Intellectual Property</h2>
        <p>
          All content on the Torino Ventures website is the property of Torino
          Ventures and is protected by intellectual property laws.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          Torino Ventures is not liable for any damages or losses resulting from
          the use of its services. Use the services at your own risk.
        </p>

        <h2>6. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of the United
          Kingdom.
        </p>

        <h2>7. Changes to Terms and Conditions</h2>
        <p>
          Torino Ventures may update these Terms and Conditions from time to
          time. Changes will be effective upon posting on the website.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at hello@torinoventures.com.
        </p>
      </section>
    </Container>
  );
}

export default TermsAndConditions;

import { Container } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Container>
      <div>
        <h1>Privacy Policy</h1>
      </div>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Torino Ventures ("we," "our," or "us"). This Privacy Policy
          explains how we collect, use, and protect your personal information
          when you use our website or services.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We may collect personal information, including but not limited to,
          your name, contact information, and any other information you
          voluntarily provide us. This information is collected for the purpose
          of providing and personalizing our services, improving and optimizing
          our website, and communicating with you, including responding to
          inquiries.
        </p>

        <h2>3. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>Providing and personalizing our services</li>
          <li>Communicating with you, including responding to inquiries</li>
        </ul>

        <h2>4. Legal Basis for Processing Personal Data</h2>
        <p>
          We process personal data based on the legal basis of legitimate
          interests. The processing is necessary for the purposes of the
          legitimate interests pursued by Torino Ventures, which include
          providing and improving our services, ensuring the security of our
          website, and communicating effectively with our users.
        </p>

        <h2>5. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or share your personal information with third
          parties without your consent, except as required by law.
        </p>

        <h2>6. Cookies</h2>
        <p>
          We use cookies to enhance your experience on our website. You can
          manage your cookie preferences through your browser settings.
        </p>

        <h2>7. Your Rights</h2>
        <p>
          You have the right to access, correct, and delete your personal data.
          If you have any concerns or requests regarding your data, please
          contact us at gdpr@torinoventures.com.
        </p>

        <h2>8. Security</h2>
        <p>
          We implement appropriate security measures to protect your personal
          information from unauthorized access and disclosure.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at gdpr@torinoventures.com.
        </p>
      </section>
    </Container>
  );
}

export default PrivacyPolicy;

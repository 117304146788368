import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation

function Footer() {
  return (
    <Box sx={{ bgcolor: "#010203", p: 6 }} component="footer">
      <div>
        <Typography variant="body2" color="background.paper" align="center">
          <Link
            to="/privacy-policy"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
          {" | "}
          <Link
            to="/terms-and-conditions"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Terms and Conditions
          </Link>
        </Typography>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <Typography variant="body2" color="background.paper" align="center">
          {"Copyright © Torino Ventures "}
          {new Date().getFullYear()}
          {"."}{" "}
        </Typography>
      </div>
    </Box>
  );
}

export default Footer;

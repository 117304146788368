import { Container, Grid, Typography } from '@mui/material';
import FeatureItem from './FeatureItem';

const FEATURES_INFO = [
  {
    title: 'Development',
    image:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1651960739/torino-ventures/laptop.jpg',
    imageAlt: 'Development',
    description: 'Software, Interface',
  },
  {
    title: 'Growth',
    image:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1651960739/torino-ventures/growth.jpg',
    imageAlt: 'Growth',
    description: 'Tools, Methodology',
  },
  {
    title: 'Journey',
    image:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1651960739/torino-ventures/journey.jpg',
    imageAlt: 'Journey',
    description: 'Support, Companionship',
  },
];

function Features() {
  return (
    <section
      id='features'
      style={{
        background: '#efefef',
        padding: '4rem 0',
      }}
    >
      <Container>
        <Typography variant='h2' align='center' gutterBottom>
          Features
        </Typography>

        <Grid container spacing={2}>
          {FEATURES_INFO.map((feature) => (
            <FeatureItem key={feature.title} feature={feature} />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

export default Features;

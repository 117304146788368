import { Button, Container, Grid, Typography } from '@mui/material';
import EventManager from '../../utils/EventManager';

function Intro() {
  function onClick() {
    EventManager.track({
      event: 'ButtonPressed',
      buttonId: 'more-info',
    });

    const anchor = document.querySelector('#features');
    if (!anchor) {
      return;
    }
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  return (
    <section
      id='home'
      style={{
        backgroundImage: `url('https://res.cloudinary.com/dcw7kvha3/image/upload/v1651961395/torino-ventures/laptop-table.jpg'), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`,
        height: '90vh',
        backgroundBlendMode: 'overlay',
      }}
    >
      <Container style={{ height: '100%' }}>
        <Grid container alignItems='center' style={{ height: '100%' }}>
          <Grid item xs={12} md={6}>
            <Typography variant='h2' color='#fff' gutterBottom>
              Web Development
            </Typography>
            <Typography color='#efefef'>
              Build something on the side with great passion.
            </Typography>
            <Typography color='#efefef' mb={2}>
              Technology is stopping you? We've got you covered!
            </Typography>
            <Button variant='contained' onClick={onClick}>
              See more
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Intro;

import TagManager from 'react-gtm-module';
import { IEvent } from '../types/events';
import Logger from './Logger';

const logger = Logger.create('EventManager');

class EventManager {
  initialize(id: string) {
    logger.log('initialize()' + id);
    TagManager.initialize({ gtmId: id });
  }

  track(event: IEvent) {
    logger.log('track()', event.event, event);

    try {
      TagManager.dataLayer({ dataLayer: event });
    } catch (e) {
      if (e instanceof Error) {
        logger.log('track(): error', e.message);
      } else {
        logger.log('track(): unexpected error', e);
      }
    }
  }

  set(dataLayer: Object) {
    try {
      TagManager.dataLayer({ dataLayer: dataLayer });
    } catch (e) {
      if (e instanceof Error) {
        logger.log('set(): error', e.message);
      } else {
        logger.log('set(): unexpected error', e);
      }
    }
  }
}

export default new EventManager();

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Header() {
  return (
    <AppBar position='relative'>
      <Toolbar style={{ minHeight: '80px' }}>
        <Container maxWidth='lg'>
          <Typography variant='h5' color='inherit' noWrap>
            Torino Ventures
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
